/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Icons() {
  return (
    <>
      <div className="section section-icons" data-background-color="black">
        <Container fluid>
          <Row>
            <Col md="3">
              <div className="icons-nucleo">
                <i className="first-left-icon now-ui-icons business_bulb-63"></i>
                <i className="second-left-icon now-ui-icons design_image"></i>
                <i className="third-left-icon now-ui-icons business_bulb-63"></i>
                <i className="fourth-left-icon now-ui-icons design_image"></i>
                <i className="fifth-left-icon now-ui-icons business_bulb-63"></i>
                <i className="sixth-left-icon now-ui-icons location_compass-05"></i>
                <i className="seventh-left-icon now-ui-icons design_image"></i>
                <i className="eighth-left-icon now-ui-icons design_image"></i>
                <i className="ninth-left-icon now-ui-icons location_compass-05"></i>
                <i className="tenth-left-icon now-ui-icons business_bulb-63"></i>
              </div>
            </Col>
            <Col className="text-center" md="6">
              <h2 className="title">Web3 Legal Engineering</h2>
              <h5 className="description">
              The Tenfinney site offer art a legal engineering related services.  This includes Web3 technology consulting, practical software development and providing roadmaps of steps necessary to be prepared for new paradigms in art and law.{" "}
                <a href="https://bafybeicf3tx6k7d5dercnpvvh7cmnkmdpefotsktauxyggern36t2m65su.ipfs.infura-ipfs.io/" target="_blank">
                  Web3 Legal Engineering provides legal frameworks that attach to NFT bundled artwork to ensure authenticity, use rights and intellectual property are protected. 
                </a>
                Tenfinney provides a customized minting smart-contract to ensure the NFT content is properly formatted to show up in popular hosting sites and galleries as well as embedding information that may become important in the future.
              </h5>
              <br></br>
              <Button
                className="btn-round mr-1"
                href="https://bafybeicf3tx6k7d5dercnpvvh7cmnkmdpefotsktauxyggern36t2m65su.ipfs.infura-ipfs.io/" 
                target="_blank"
                color="info"
                size="lg"
              >
                View Self-Executing Demo
              </Button>
              <Button
                className="btn-round"
                color="default"
                href="https://opensea.io/accounts/tenfinney"
                target="_blank"
                size="lg"
              >
                See Catalog in OpenSea
              </Button>
            </Col>
            <Col md="3">
              <div className="icons-nucleo icons-nucleo-right">
                <i className="first-right-icon now-ui-icons objects_spaceship"></i>
                <i className="second-right-icon now-ui-icons shopping_cart-simple"></i>
                <i className="third-right-icon now-ui-icons loader_gear spin"></i>
                <i className="fourth-right-icon now-ui-icons objects_spaceship"></i>
                <i className="fifth-right-icon now-ui-icons loader_gear spin"></i>
                <i className="sixth-right-icon now-ui-icons shopping_cart-simple"></i>
                <i className="seventh-right-icon now-ui-icons objects_spaceship"></i>
                <i className="eighth-right-icon now-ui-icons loader_gear spin"></i>
                <i className="ninth-right-icon now-ui-icons location_compass-05"></i>
                <i className="tenth-right-icon now-ui-icons location_compass-05"></i>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Icons;
