import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Content() {
  return (
    <>
      <div className="section section-content" data-background-color="black">
        <Container>
          <Row>
            <Col md="5">
              <div className="image-container">
                <img
                  alt="..."
                  className="img img-raised rounded img-comments rellax"
                  data-rellax-speed="1"
                  src={require("assets/img/presentation-page/pres17.png")}
                ></img>
                <img
                  alt="..."
                  className="img img-raised rounded img-blog rellax"
                  data-rellax-speed="4"
                  src={require("assets/img/presentation-page/pres18.png")}
                ></img>
              </div>
            </Col>
            <Col className="ml-auto mr-auto" md="4">
              <div className="section-description">
                <h2 className="title">Web3 Projects Require Project Management</h2>
                <h6 className="category">New technology is dificult to grasp at first.</h6>
                <h5 className="description">
                  Web3 encompasses some ground-breaking technologies in node computing, public blockchain, encryption schemas, and immutable records. Web3 offers solutions for both art and law with assurances of authenticity and relaibility required for succesfull art asset management.
                </h5>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Content;
