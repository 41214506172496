import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function Examples() {
  return (
    <>
      <div className="section section-examples" data-background-color="black">
        <Container>
          <Col className="ml-auto mr-auto text-center" md="8">
            <div className="section-description">
              <h2 className="title">Current Art Collections</h2>
              <h5 className="description">
                Forget about building everything from scratch. From converting assets to a digital form, to embedding metadata into a mintable NFT, and cloning it to your customers, Tenfinney and professional Web3 consultants will guide and manage your project from the product development stages of content creation through the initial promotions currated events held live audiences in Cryptovoxels metaverse galleries.
              </h5>
            </div>
          </Col>
          <Row>
            <Col md="4">
              <h5 className="title">Beehive 08: 1 of 11</h5>
              <Card>
                <Link to="/product-page">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs01.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Beehive 09: 1 of 11</h5>
              <Card>
                <Link to="/product-page">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs02.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Blasts 05: 1 of 11</h5>
              <Card>
                <Link to="/product-page">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs03.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Nexus 01: 1 of 11</h5>
              <Card>
                <Link to="/product-page">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs04.png")}
                  ></img>
                </Link>
              </Card>
            </Col>
            <Col md="4">
              <h5 className="title">Paisley 04: 1 of 11</h5>
              <Card>
                <Link to="/product-page">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs05.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Paisley 06: 1 of 11</h5>
              <Card>
                <Link to="/product-page">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs06.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Serpent 04: 1 of 11</h5>
              <Card>
                <Link to="/product-page">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs07.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Serpent 10: 1 of 11</h5>
              <Card>
                <Link to="/product-page">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs08.png")}
                  ></img>
                </Link>
              </Card>
            </Col>
            <Col md="4">
              <h5 className="title">Serpent 13: 1 of 11</h5>
              <Card>
                <Link to="/e-commerce">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs09.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Stellar 02: 1 of 11</h5>
              <Card>
                <Link to="/e-commerce">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs10.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Stellar 03: 1 of 11</h5>
              <Card>
                <Link to="/e-commerce">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs11.png")}
                  ></img>
                </Link>
              </Card>
              <h5 className="title">Storm 02: 1 of 11</h5>
              <Card>
                <Link to="/e-commerce">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pages/pres_pgs12.png")}
                  ></img>
                </Link>
              </Card>

            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Examples;
