import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardLink,
  CardTitle,
  Collapse,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import ProductPageHeader from "components/Headers/ProductPageHeader.js";
import FooterSocial from "components/Footers/FooterSocial.js";

const items = [
  {
    src: require("assets/img/bg29.png"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/bg30.png"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/bg31.png"),
    altText: "",
    caption: "",
  },
  {
    src: require("assets/img/bg32.png"),
    altText: "",
    caption: "",
  },
];

function ProductPage() {
  // carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // select states and functions
  const [colorSelect, setColorSelect] = React.useState(null);
  const [sizeSelect, setSizeSelect] = React.useState(null);
  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <ProductPageHeader />
        <div className="section">
          <Container>
            <Row>
              <Col md="5">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img
                          src={item.src}
                          alt={item.altText}
                          className="d-block img-raised"
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      name="button"
                      size="sm"
                      type="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </Button>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      name="button"
                      size="sm"
                      type="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </Button>
                  </a>
                </Carousel>
                <p className="blockquote blockquote-info">
                  "It is a real thrill to combine math and computer imagery and to test the bounds of inlellectual property, commerce and art." <br></br>
                  <br></br>
                  <small>tenfinney</small>
                </p>
              </Col>
              <Col className="ml-auto mr-auto" md="6">
                <h2 className="title">Escape to Infinity</h2>
                <h5 className="category">Computer Generated Quasi Self-Similar Fractals</h5>
                <h2 className="main-price">500 Finney (0.5 Ether)</h2>
                <div
                  aria-multiselectable={true}
                  className="card-collapse"
                  id="accordion"
                  role="tablist"
                >
                  <Card className="card-plain">
                    <CardHeader id="headingOne" role="tab">
                      <a
                        aria-expanded={collapses.includes(1)}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          changeCollapse(1);
                        }}
                      >
                        Description{" "}
                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                      </a>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(1)}>
                      <CardBody>
                        <p>
                        Fractal geometry lies within the mathematical branch of measure theory. Starting in the 17th century with notions of recursion, fractals have moved through increasingly rigorous mathematical treatment to the study of continuous but not differentiable functions.  Fractals are a subset of Euclidean space in mathematics with a fractal dimension that strictly exceeds its topological dimension. The term fractal was used by mathematician Benoit Mandelbrot, and is based on the Latin frāctus, meaning fractured. Extending the concept of theoretical fractional dimensions to geometric patterns in nature. 

                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card className="card-plain">
                    <CardHeader id="headingTwo" role="tab">
                      <a
                        aria-expanded={collapses.includes(2)}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          changeCollapse(2);
                        }}
                      >
                        Colection Information{" "}
                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                      </a>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(2)}>
                      <CardBody>
                        <p>
                        Quasi self-similar fractals reveal ever-finer recursive details progressively at increasing magnifications. Naturally occuring self-similar features can be found in leaves, blood vessels, algae, ocean waves, snowflakes, and even Romanesco broccoli. The Escape to Infinity collection includes multiple components to emphasize the power of the non-fungible token (NFT). This includes (1) an animated segment of the fractal in motion and (2) a self-executing file showing the fractal set in motion. These elements are all combined with a (3) Certificate of Authenticity, a description of the (4) Rights to Use and Resale, (5) a statement of Limits to Reproductions and (6) a Jurisdictional statement. All these elements are combined within this file that permanently attaches to the NFT. Additionally, there is a permanent link (7) to a document that explains in basic terms the mathematical theories of the escape-time algorithm resulting in The Escape to Infinty collection. In the event that the NFT needs to be transferred to another public blockchain, there are embedded fields that may assist in providing cross-chain use of this NFT. 
                        </p>
                      </CardBody>
                    </Collapse>
                  </Card>
                  <Card className="card-plain">
                    <CardHeader id="headingThree" role="tab">
                      <a
                        aria-expanded={collapses.includes(3)}
                        data-parent="#accordion"
                        data-toggle="collapse"
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          changeCollapse(3);
                        }}
                      >
                        Image Details{" "}
                        <i className="now-ui-icons arrows-1_minimal-down"></i>
                      </a>
                    </CardHeader>
                    <Collapse isOpen={collapses.includes(3)}>
                      <CardBody>
                        <ul>
                          <li>metadata here</li>
                          <li>metadata here</li>
                          <li>metadata here</li>
                          <li>metadata here</li>
                          <li>metadata here</li>
                          <li>metadata here</li>
                        </ul>
                      </CardBody>
                    </Collapse>
                  </Card>
                </div>
                <Row className="pick-size">
                  <Col lg="6" md="8" sm="6">
                    <label>Select color</label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name=""
                      onChange={(value) => setColorSelect(value)}
                      options={[
                        { value: "1", label: "Black" },
                        { value: "2", label: "Gray" },
                        { value: "3", label: "White" },
                      ]}
                      placeholder="Select color"
                      value={colorSelect}
                    ></Select>
                  </Col>
                  <Col lg="6" md="8" sm="6">
                    <label>Select size</label>
                    <Select
                      className="react-select"
                      classNamePrefix="react-select"
                      name=""
                      onChange={(value) => setSizeSelect(value)}
                      options={[
                        { value: "1", label: "Small " },
                        { value: "2", label: "Medium" },
                        { value: "3", label: "Large" },
                      ]}
                      placeholder="Select size"
                      value={sizeSelect}
                    ></Select>
                  </Col>
                </Row>
                <Row className="justify-content-end">
                  <Button className="mr-3" color="info">
                    Add to Cart  
                    <i className="now-ui-icons shopping_cart-simple"></i>
                  </Button>
                </Row>
              </Col>
            </Row>
            <div className="section">
              <Row>
                <Col className="ml-auto mr-auto text-center mr-5" md="8">
                  <h2 className="title">How to evaluate the NFT.</h2>
                  <h4 className="description">
                  The images of this collection and set elements are all combined with a Certificate of Authenticity, a description of the Rights to Use and Resale, a statement of Limits to Reproductions and a Jurisdictional statement. All these elements are combined within this file that permanently attaches to the NFT. Additionally, there is a permanent link to a document that explains in basic terms the mathematical theories of the escape-time algorithm resulting in The Escape to Infinty collection. In the event that the NFT needs to be transferred to another public blockchain, there are embedded fields that may assist in providing cross-chain use of this NFT. 
                  </h4>
                </Col>
              </Row>
              <div className="section-story-overview">
                <Row>
                  <Col className="ml-auto mr-auto" md="6">
                    <div
                      className="image-container image-left"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg31.png") + ")",
                      }}
                    >
                      <p className="blockquote blockquote-info">
                      Colors on each plotted point represents how quickly the values reached the escape point.  <br></br>
                      </p>
                      
                    </div>
                    <div
                      className="image-container"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg30.png") + ")",
                      }}
                    ></div>
                  </Col>
                  <Col className="ml-auto mr-auto" md="4">
                    <div
                      className="image-container image-right"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg32.png") + ")",
                      }}
                    ></div>
                    <h3>
                    The Mandelbrot fractal sets.
                    </h3>
                    <p>
                    Images of the Mandelbrot set exhibit an elaborate and infinitely complicated boundary that reveals progressively ever-finer recursive detail at increasing magnifications, making the boundary of the Mandelbrot set a fractal curve. The "style" of this repeating detail depends on the region of the set being examined.[2]
                    </p>

                  </Col>
                </Row>
              </div>
            </div>
            <div className="features-4">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">Why fractals matter.</h2>
                    <h4 className="description">
                    Fractal generation software can be used to mimic natural landscapes with fractal landscapes and scenery generation programs. Fractal imagery can be used to introduce irregularity to an otherwise sterile computer generated environment. They are used in music visualization software, screensavers and wallpaper generators. Because generating fractals is time-consuming and requires many computations it is often used in computer benchmarking devices.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Card
                      className="card-background card-raised"
                      data-background-color=""
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg24.png") + ")",
                      }}
                    >
                      <div className="info">
                        <div className="icon icon-white">
                          <i className="now-ui-icons shopping_delivery-fast"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Instant Delivery</h4>
                          <p>
                            NFT purchases are finalized automatically upon signing an Ethereum based transaction. The NFT will be transferred to the 0x address that executes the pulic blockchain transaction. Once the Ethereum nodes recieve a verifiable consesus the token s aotomatically transferred.
                          </p>
                          <a
                            className="ml-3"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Find out more...
                          </a>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card
                      className="card-background card-raised"
                      data-background-color=""
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg28.png") + ")",
                      }}
                    >
                      <div className="info">
                        <div className="icon icon-white">
                          <i className="now-ui-icons business_badge"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Deep-set Metadata</h4>
                          <p>
                          Exif is used as a platform-independent command-line application for reading, writing and editing meta information in a wide variety of files and is used to embed valable information and content directly into the NFT images to ensure authenticity.
                          </p>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            Find out more...
                          </a>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card
                      className="card-background card-raised"
                      data-background-color=""
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg25.png") + ")",
                      }}
                    >
                      <div className="info">
                        <div className="icon">
                          <i className="now-ui-icons ui-2_favourite-28"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Assurance Against Loss</h4>
                          <p>
                            Risk of Loss details include a signed statement signed which represent a condition of loss or claim between the time the NFT was acquired and can no longer be accessed. Tenfinney as issuer has safeguards to compensate the NFT holders against any total loss.
                          </p>
                          <a
                            className="ml-3"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Find out more...
                          </a>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        </div>
        <div className="section related-products" data-background-color="black">
          <Container>
            <h3 className="title text-center">
              You may also be interested in:
            </h3>
            <Row>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/presentation-page/pres92.png")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-danger">Trending</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Storm Fractal Set
                      </CardLink>
                    </CardTitle>
                    <div className="card-description">
                    The term Mandelbrot set is used to refer both to a general class of fractal sets and to a particular instance of such a set. In general, a Mandelbrot set marks the set of points in the complex plane.[1]
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">500 Finney</span>
                      </div>
                      <Button
                        className="btn-neutral btn-icon btn-round pull-right"
                        color="default"
                        data-placement="left"
                        id="tooltip963523139"
                      >
                        <i className="now-ui-icons ui-2_favourite-28"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="left"
                        target="tooltip963523139"
                      >
                        Add to wishlist
                      </UncontrolledTooltip>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/presentation-page/pres87.png")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-muted">Popular</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Stellar Fractal Set
                      </CardLink>
                    </CardTitle>
                    <div className="card-description">
                    The term Mandelbrot set is used to refer both to a general class of fractal sets and to a particular instance of such a set. In general, a Mandelbrot set marks the set of points in the complex plane.[1]
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">500 Finney</span>
                      </div>
                      <Button
                        className="btn-neutral btn-icon btn-round pull-right"
                        color="default"
                        data-placement="left"
                        id="tooltip788385879"
                      >
                        <i className="now-ui-icons ui-2_favourite-28"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="left"
                        target="tooltip788385879"
                      >
                        Add to wishlist
                      </UncontrolledTooltip>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/presentation-page/pres64.png")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-muted">Popular</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Serpent Fractal Set
                      </CardLink>
                    </CardTitle>
                    <div className="card-description">
                    The term Mandelbrot set is used to refer both to a general class of fractal sets and to a particular instance of such a set. In general, a Mandelbrot set marks the set of points in the complex plane.[1]
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">500 Finney</span>
                      </div>
                      <Button
                        className="btn-neutral btn-icon btn-round pull-right"
                        color="default"
                        data-placement="left"
                        id="tooltip29821793"
                      >
                        <i className="now-ui-icons ui-2_favourite-28"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="left"
                        target="tooltip29821793"
                      >
                        Add to wishlist
                      </UncontrolledTooltip>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3" sm="6">
                <Card className="card-product">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/presentation-page/pres07.png")}
                      ></img>
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="category text-rose">Trending</h6>
                    <CardTitle tag="h4">
                      <CardLink
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Paisley Fractal Set
                      </CardLink>
                    </CardTitle>
                    <div className="card-description">
                    The term Mandelbrot set is used to refer both to a general class of fractal sets and to a particular instance of such a set. In general, a Mandelbrot set marks the set of points in the complex plane.[1]
                    </div>
                    <CardFooter>
                      <div className="price-container">
                        <span className="price">500 Finney</span>
                      </div>
                      <Button
                        className="btn-neutral btn-icon btn-round pull-right"
                        color="default"
                        data-placement="left"
                        id="tooltip338597952"
                      >
                        <i className="now-ui-icons ui-2_favourite-28"></i>
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="left"
                        target="tooltip338597952"
                      >
                        Add to wishlist
                      </UncontrolledTooltip>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <FooterSocial />
      </div>
    </>
  );
}

export default ProductPage;
