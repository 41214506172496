/*eslint-disable*/
import React from "react";

// reactstrap components

// core components

function PresentationHeader() {
  return (
    <>
      <div className="page-header clear-filter">
        <div className="rellax-header rellax-header-sky" data-rellax-speed="-4">
          <div
            className="page-header-image"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/presentation-page/nuk-pro-back-sky.jpg") +
                ")",
            }}
          ></div>
        </div>
        <div
          className="rellax-header rellax-header-buildings"
          data-rellax-speed="0"
        >
          <div
            className="page-header-image page-header-city"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/presentation-page/cover00.png") +
                ")",
            }}
          ></div>
        </div>
        <div className="rellax-text-container rellax-text">
          <h1 className="h1-seo" data-rellax-speed="-1">
            Ten finney
          </h1>
          <div className="pro">ART+</div>
        </div>
        <h3 className="h3-description rellax-text" data-rellax-speed="-1">
          Where art, math and law intersect.
        </h3>
        {/* <h6
          className="category category-absolute rellax-text"
          data-rellax-speed="-1"
        >
          Designed by{" "}
          <a href="https://tenfinney.com" target="_blank">
            <img
              alt="..."
              className="invision-logo"
              src={require("assets/img/ss-white-slim.png")}
            ></img>
          </a>
          . Coded by{" "}
          <a
            href="https://tenfinney.com"
            target="_blank"
          >
            <img
              alt="..."
              className="creative-tim-logo"
              src={require("assets/img/w3le.png")}
            ></img>
          </a>
          .
        </h6> */}
      </div>
    </>
  );
}

export default PresentationHeader;
