/*eslint-disable*/
import React from "react";
// plugin that creates slider
import Slider from "nouislider";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Collapse,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import EcommerceHeader from "components/Headers/EcommerceHeader.js";
import Footer from "components/Footers/Footer.js";

function Ecommerce() {
  // focus for inputs
  const [emailFocus, setEmailFocus] = React.useState(false);
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };




  // slider states and functions
  const [sliderMin, setSliderMin] = React.useState(100);
  const [sliderMax, setSliderMax] = React.useState(800);
  React.useEffect(() => {
    if (
      !document.getElementById("sliderRefine").classList.contains("noUi-target")
    ) {
      Slider.create(document.getElementById("sliderRefine"), {
        start: [sliderMin, sliderMax],
        connect: [false, true, false],
        step: 1,
        range: { min: 30, max: 900 },
      }).on("update", function (values) {
        setSliderMin(Math.round(values[0]));
        setSliderMax(Math.round(values[1]));
      });
    }

    document.body.classList.add("ecommerce-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("ecommerce-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <ScrollTransparentNavbar />
      <div className="wrapper">
        <EcommerceHeader />
        <div className="main">
          <div className="section">
            <Container>
              <h2 className="section-title">Each unique fractal art set was rendered using <code> Z=Z<sup>2</sup>+C </code>and the escape-time algorithm. Custom color variants complete each set.</h2>
              <Row>
                <Col md="3">
                  <div className="collapse-panel">
                    <CardBody>
                      <Card className="card-refine card-plain">
                        <CardTitle tag="h4">
                          Refine{" "}
                          <Button
                            className="btn-icon btn-neutral pull-right"
                            color="default"
                            id="tooltip633919451"
                          >
                            <i className="arrows-1_refresh-69 now-ui-icons"></i>
                          </Button>
                          <UncontrolledTooltip
                            delay={4}
                            target="tooltip633919451"
                          >
                            Reset Filter
                          </UncontrolledTooltip>
                        </CardTitle>
                        <CardHeader id="headingOne" role="tab">
                          <h6 className="mb-0">
                            <a
                              className="text-info"
                              aria-expanded={collapses.includes(1)}
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                changeCollapse(1);
                              }}
                            >
                              Price Range{" "}
                              <i className="now-ui-icons arrows-1_minimal-down"></i>
                            </a>
                          </h6>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(1)}>
                          <CardBody>
                            <span
                              className="price-left pull-left"
                              id="price-left"
                            >
                              {sliderMin}{" "}Finney 
                            </span>
                            <span
                              className="price-right pull-right"
                              id="price-right"
                            >
                              {sliderMax}{" "}Finney 
                            </span>
                            <div className="clearfix"></div>
                            <div
                              className="slider slider-refine"
                              id="sliderRefine"
                            ></div>
                          </CardBody>
                        </Collapse>
                      </Card>
                      <Card className="card-refine card-plain">
                        <CardHeader id="headingTwo" role="tab">
                          <h6>
                            <a
                              className="text-info"
                              aria-expanded={collapses.includes(2)}
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                changeCollapse(2);
                              }}
                            >
                              Viewpoint{" "}
                              <i className="now-ui-icons arrows-1_minimal-down"></i>
                            </a>
                          </h6>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(2)}>
                          <CardBody>
                            <FormGroup check>
                              <Label check>
                                <Input defaultChecked type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Full-size fractal
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Zoom Section 1
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                              <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Zoom Section 2
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                              <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Zoom Section 3
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Self executing fractal
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Animated fractal
                              </Label>
                            </FormGroup>
                          </CardBody>
                        </Collapse>
                      </Card>
                      <Card className="card-refine card-plain">
                        <CardHeader id="headingThree" role="tab">
                          <h6>
                            <a
                              className="text-info"
                              aria-expanded={collapses.includes(3)}
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                changeCollapse(3);
                              }}
                            >
                              Collection Sets{" "}
                              <i className="now-ui-icons arrows-1_minimal-down"></i>
                            </a>
                          </h6>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(3)}>
                          <CardBody>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                All
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Beehive Set
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Blast Set
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Diamond Set
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Galaxy Set
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Nexus Set
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Paisley Set
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Serpent Set
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Stellar Set
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Escape to Infinity: Storm Set
                              </Label>
                            </FormGroup>
                          </CardBody>
                        </Collapse>
                      </Card>
                      <Card className="card-refine card-plain">
                        <CardHeader id="headingfour" role="tab">
                          <h6>
                            <a
                              className="text-info"
                              aria-expanded={collapses.includes(4)}
                              data-toggle="collapse"
                              data-parent="#accordion"
                              href="#pablo"
                              onClick={(e) => {
                                e.preventDefault();
                                changeCollapse(4);
                              }}
                            >
                              Color Combo{" "}
                              <i className="now-ui-icons arrows-1_minimal-down"></i>
                            </a>
                          </h6>
                        </CardHeader>
                        <Collapse isOpen={collapses.includes(4)}>
                          <CardBody>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Blue to Infinity Black
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Gray blue to Infinity Black
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Purple to Infinity Black
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Green to Infinity Black
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Orange to Infinity Black
                              </Label>
                            </FormGroup>
                            <FormGroup check>
                              <Label check>
                                <Input type="checkbox"></Input>
                                <span className="form-check-sign"></span>
                                Cyan to Infinity Black
                              </Label>
                            </FormGroup>
                          </CardBody>
                        </Collapse>
                      </Card>
                    </CardBody>
                  </div>
                </Col>
                <Col md="9">
                  <Row>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/bg2.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Beehive Set</CardTitle>
                          </a>
                          <p className="card-description">
                          Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="danger"
                              data-placement="left"
                              id="tooltip719224088"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip719224088"
                            >
                              Remove from wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/bg44.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Blast Set</CardTitle>
                          </a>
                          <p className="card-description">
                          Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="default"
                              data-placement="left"
                              id="tooltip44332731"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip44332731"
                            >
                              Add to wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/gen_img18.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Diamond Set</CardTitle>
                          </a>
                          <p className="card-description">
                          Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="default"
                              data-placement="left"
                              id="tooltip601285753"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip601285753"
                            >
                              Add to wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/gen_img27.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Galaxy Set</CardTitle>
                          </a>
                          <p className="card-description">
                          Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="default"
                              data-placement="left"
                              id="tooltip931109693"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip931109693"
                            >
                              Add to wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/gen_img35.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Nexus Set</CardTitle>
                          </a>
                          <p className="card-description">
                          Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="default"
                              data-placement="left"
                              id="tooltip512086450"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip512086450"
                            >
                              Remove from wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/gen_img64.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Paisley Set</CardTitle>
                          </a>
                          <p className="card-description">
                            Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="default"
                              data-placement="left"
                              id="tooltip867244968"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip867244968"
                            >
                              Add to wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/mini08.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Serpent Set</CardTitle>
                          </a>
                          <p className="card-description">
                          Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="default"
                              data-placement="left"
                              id="tooltip931109693"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip931109693"
                            >
                              Add to wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/bg22.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Stellar Set</CardTitle>
                          </a>
                          <p className="card-description">
                          Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="default"
                              data-placement="left"
                              id="tooltip512086450"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip512086450"
                            >
                              Remove from wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/presentation-page/pres91.png")}
                            ></img>
                          </a>
                        </div>
                        <CardBody>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <CardTitle tag="h4">Escape to Infinity: Storm Set</CardTitle>
                          </a>
                          <p className="card-description">
                            Masterfully crafted on a Linux 5.0.0-38-generic #41-Ubuntu.
                          </p>
                          <CardFooter>
                            <div className="price-container">
                              <span className="price">500 Finney (0.5 Ether)</span>
                            </div>
                            <Button
                              className="btn-neutral btn-icon btn-round pull-right"
                              color="default"
                              data-placement="left"
                              id="tooltip867244968"
                            >
                              <i className="now-ui-icons ui-2_favourite-28"></i>
                            </Button>
                            <UncontrolledTooltip
                              delay={0}
                              placement="left"
                              target="tooltip867244968"
                            >
                              Add to wishlist
                            </UncontrolledTooltip>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col className="ml-auto mr-auto" md="3">
    
    <hr></hr>
                      {/* <Button
                        className="btn-round"
                        color="info"
                        id="tooltip51956639"
                      >
                        Load more...
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="tooltip51956639"
                      ></UncontrolledTooltip> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Container>
            <h2 className="section-title">Escape to Infinity - Computer Generated Quasi Self-Similar Fractals</h2>
          </Container>
          <div className="projects-4">
            <Container fluid>
              <Row>
                <Col className="px-0" md="6">
                  <Card
                    className="card-fashion card-background"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/bg35.png") + ")",
                    }}
                  >
                    <CardBody>
                      <CardTitle className="text-left" tag="div">
                        <h2>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          The escape-time algorithm
                          </a>
                        </h2>
                      </CardTitle>
                      <CardFooter className="text-left">
                        <div className="stats">
                          <span>
                            <i className="now-ui-icons users_circle-08"></i>
                            Scott Stevenson
                          </span>
                          <span>
                            <i className="now-ui-icons tech_watch-time"></i>
                            March 6, 2021
                          </span>
                        </div>
                        <div className="stats-link pull-right">
                          <a
                            className="footer-link"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Fractals in Action
                          </a>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="px-0" md="6">
                  <div className="card-container">
                    <Card className="card-fashion">
                      <CardTitle tag="div">
                        <h5>
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          The simplest algorithm for generating a representation of the Mandelbrot set is known as the "escape-time" algorithm. A repeating calculation is performed for each x, y point in the plot area and based on the behavior of that calculation.

                          </a>
                        </h5>
                      </CardTitle>
                      <CardBody>
                        <CardFooter className="text-left">
                          <div className="stats">
                            <span>
                              <i className="now-ui-icons users_circle-08"></i>
                              Scott Stevenson
                            </span>
                            <span>
                              <i className="now-ui-icons tech_watch-time"></i>
                              March 6, 2021
                            </span>
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                    <Card
                      className="card-fashion card-background"
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/bg40.png") + ")",
                      }}
                    ></Card>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="section">
            <Container>
              <h2 className="section-title">Latest Released Sets</h2>
              <Row>
                <Col md="4">
                  <Card className="card-product card-plain">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/examples/serpent-10.png")}
                      ></img>
                    </div>
                    <CardBody>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Serpent Set #10
                        </a>
                      </CardTitle>
                      <p className="card-description">
                      The Mandelbrot set is the set obtained from the quadratic recurrence equation z_(n+1)=z_n<sup>2</sup>+C where points C in the complex plane for which the orbit of z_n does not tend to infinity are in the set. 

                      </p>
                      <CardFooter>
                        <div className="price-container">
                          <span className="price mr-1">0.5 Ether is</span>
                          <span className="price price-new"> 500 Finney</span>
                        </div>
                        <div className="stats stats-right">
                          <Button
                            className="btn-icon"
                            color="neutral"
                            id="tooltip777725160"
                            type="button"
                          >
                            <i className="now-ui-icons ui-2_favourite-28"></i>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip777725160"
                          >
                            Saved to Wishlist
                          </UncontrolledTooltip>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-product card-plain">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/bg45.png")}
                      ></img>
                    </div>
                    <CardBody>
                      <CardTitle tag="h4">Stellar Set #02</CardTitle>
                      <p className="card-description">
                      The Mandelbrot set is the set obtained from the quadratic recurrence equation z_(n+1)=z_n<sup>2</sup>+C where points C in the complex plane for which the orbit of z_n does not tend to infinity are in the set. 
                      </p>
                      <CardFooter>
                        <div className="price-container">
                          <span className="price mr-1">0.5 Ether is</span>
                          <span className="price price-new">500 Finney</span>
                        </div>
                        <div className="stats stats-right">
                          <Button
                            className="btn-icon"
                            color="neutral"
                            id="tooltip127778557"
                            type="button"
                          >
                            <i className="now-ui-icons ui-2_favourite-28"></i>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip127778557"
                          >
                            Saved to Wishlist
                          </UncontrolledTooltip>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-product card-plain">
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/examples/nexuses-02.png")}
                      ></img>
                    </div>
                    <CardBody>
                      <CardTitle tag="h4">Nexus Set #01</CardTitle>
                      <p className="card-description">
                      The Mandelbrot set is the set obtained from the quadratic recurrence equation z_(n+1)=z_n<sup>2</sup>+C where points C in the complex plane for which the orbit of z_n does not tend to infinity are in the set. 
                      </p>
                      <CardFooter>
                        <div className="price-container">
                          <span className="price mr-1">0.5 Ether is</span>
                          <span className="price price-new">500 Finney</span>
                        </div>
                        <div className="stats stats-right">
                          <Button
                            className="btn-icon btn-neutral"
                            color="default"
                            id="tooltip221340378"
                            type="button"
                          >
                            <i className="now-ui-icons ui-2_favourite-28"></i>
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip221340378"
                          >
                            Add to Wishlist
                          </UncontrolledTooltip>
                        </div>
                      </CardFooter>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className="subscribe-line subscribe-line-image"
            style={{
              backgroundImage: "url(" + require("assets/img/bg43.png") + ")",
            }}
          >
            {/* <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="6">
                  <div className="text-center">
                    <h4 className="title">Subscribe to our Newsletter</h4>
                    <p className="description">
                      Join our and get news about Web3 art.
                    </p>
                  </div>
                  <Card className="card-raised card-form-horizontal">
                    <CardBody>
                      <Form action="" method="">
                        <Row>
                          <Col sm="8">
                            <InputGroup
                              className={emailFocus ? "input-group-focus" : ""}
                            >
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="now-ui-icons ui-1_email-85"></i>
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Email Here..."
                                type="text"
                                onFocus={() => setEmailFocus(true)}
                                onBlur={() => setEmailFocus(false)}
                              ></Input>
                            </InputGroup>
                          </Col>
                          <Col sm="4">
                            <Button block color="info" type="button">
                              Subscribe
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container> */}


          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Ecommerce;
