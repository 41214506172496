import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function Sections() {
  return (
    <>
      <div className="section section-sections" data-background-color="gray">
        <Container>
          <Col className="ml-auto mr-auto" md="8">
            <div className="section-description text-center">
              <h2 className="title">Legal Engineering for Web3 Art</h2>
              <h5 className="description">
                Tenfinney and Web3 Legal Engineering offers a host of Web3 related services. Whether building a website for the first time or designing a complex applicationfor an enterprise data managment system, Scott Stevenson and a team of professional legal engineers craft valuable solutions.               
                </h5>
              <Button
                className="btn-round"
                to="/e-commerce"
                color="info"
                tag={Link}
              >
                View All Engineering Services
              </Button>
            </div>
          </Col>
        </Container>
        <Container fluid>
          <div className="section-cols">
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres27.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres28.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres29.png")}
                ></img>
                <img
                  alt="..."
                  className="rellax"
                  data-rellax-speed="4"
                  src={require("assets/img/presentation-page/pres41.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres30.png")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres19.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres06.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres21.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres35.png")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres36.png")}
                ></img>
                <img
                  alt="..."
                  className="rellax"
                  data-rellax-speed="5"
                  src={require("assets/img/presentation-page/pres05.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres37.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres24.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres25.png")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres02.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres03.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres04.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres05.png")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres06.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres47.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres48.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres85.png")}
                ></img>
                <img
                  alt="..."
                  className="rellax"
                  data-rellax-speed="5"
                  src={require("assets/img/presentation-page/pres59.png")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres86.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres87.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres81.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres82.png")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres83.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres84.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/gen_img34.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/gen_img35.png")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/gen_img63.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres40.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres41.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres49.png")}
                ></img>
              </Col>
            </Row>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres50.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres51.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/gen_img05.png")}
                ></img>
              </Col>
              <Col md="3">
                <img
                  alt="..."
                  src={require("assets/img/presentation-page/pres15.png")}
                ></img>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Sections;
