import React from "react";
// react plugin used to create google maps
// import {
//   withScriptjs,
//   withGoogleMap,
//   GoogleMap,
//   Marker,
// } from "react-google-maps";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DropdownWhiteNavbar from "components/Navbars/DropdownWhiteNavbar.js";
import ContactUsHeader from "components/Headers/ContactUsHeader.js";
import Footer from "components/Footers/Footer.js";

// const MapWrapper = withScriptjs(
//   withGoogleMap((props) => (
//     <GoogleMap
//       defaultZoom={13}
//       defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
//       defaultOptions={{
//         scrollwheel: false,
//         styles: [
//           {
//             featureType: "water",
//             elementType: "geometry",
//             stylers: [{ color: "#e9e9e9" }, { lightness: 17 }],
//           },
//           {
//             featureType: "landscape",
//             elementType: "geometry",
//             stylers: [{ color: "#f5f5f5" }, { lightness: 20 }],
//           },
//           {
//             featureType: "road.highway",
//             elementType: "geometry.fill",
//             stylers: [{ color: "#ffffff" }, { lightness: 17 }],
//           },
//           {
//             featureType: "road.highway",
//             elementType: "geometry.stroke",
//             stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }],
//           },
//           {
//             featureType: "road.arterial",
//             elementType: "geometry",
//             stylers: [{ color: "#ffffff" }, { lightness: 18 }],
//           },
//           {
//             featureType: "road.local",
//             elementType: "geometry",
//             stylers: [{ color: "#ffffff" }, { lightness: 16 }],
//           },
//           {
//             featureType: "poi",
//             elementType: "geometry",
//             stylers: [{ color: "#f5f5f5" }, { lightness: 21 }],
//           },
//           {
//             featureType: "poi.park",
//             elementType: "geometry",
//             stylers: [{ color: "#dedede" }, { lightness: 21 }],
//           },
//           {
//             elementType: "labels.text.stroke",
//             stylers: [
//               { visibility: "on" },
//               { color: "#ffffff" },
//               { lightness: 16 },
//             ],
//           },
//           {
//             elementType: "labels.text.fill",
//             stylers: [
//               { saturation: 36 },
//               { color: "#333333" },
//               { lightness: 40 },
//             ],
//           },
//           { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
//           {
//             featureType: "transit",
//             elementType: "geometry",
//             stylers: [{ color: "#f2f2f2" }, { lightness: 19 }],
//           },
//           {
//             featureType: "administrative",
//             elementType: "geometry.fill",
//             stylers: [{ color: "#fefefe" }, { lightness: 20 }],
//           },
//           {
//             featureType: "administrative",
//             elementType: "geometry.stroke",
//             stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }],
//           },
//         ],
//       }}
//     >
//       <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
//     </GoogleMap>
//   ))
// );

function ContactUs() {
  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [numberFocus, setNumberFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("contact-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contact-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownWhiteNavbar />
      <div className="wrapper">
        <ContactUsHeader />
        <div className="main">
          <div className="contact-content">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="5">
                  <h2 className="title">Contact</h2>
                  <p className="description">
                    Thank you for interest. <br></br>
                    <br></br>
                  </p>
                  <Form id="contact-form" method="post" role="form">
                    <label>Name or 0x address</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        aria-label="Your Name..."
                        autoComplete="name"
                        placeholder="Your Name..."
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Email address (not required)</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        aria-label="Email Here..."
                        autoComplete="email"
                        placeholder="Email Here..."
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Phone (not required)</label>
                    <InputGroup
                      className={numberFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons tech_mobile"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        autoComplete="number"
                        placeholder="Number Here..."
                        type="text"
                        onFocus={() => setNumberFocus(true)}
                        onBlur={() => setNumberFocus(false)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label>Your message</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                      ></Input>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        className="btn-raised btn-round"
                        color="info"
                        defaultValue="Contact Us"
                        type="submit"
                      >
                        Contact Us
                      </Button>
                    </div>
                  </Form>
                </Col>
                <Col className="ml-auto mr-auto" md="5">
                  <div className="info info-horizontal mt-5">
                    <div className="icon icon-info">
                      <i className="now-ui-icons location_world"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Location</h4>
                      <p>
                        Laguna Hills <br></br>
                        CA USA <br></br>
                        </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="now-ui-icons tech_mobile"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Phone</h4>
                      <p>
                        Scott Stevenson <br></br>
949 480-9045 <br></br>
877 684-2988 <br></br>
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="business_briefcase-24 now-ui-icons"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Legal</h4>
                      <p>
                        Web 3 Legal Engineering. <br></br>
                        Global REO XVIII LLC <br></br>
                        <br></br>
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        {/* <div className="big-map" id="contactUs2Map">
          <MapWrapper
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAuUX3tS4c0byzuWSkpumxKkEU8IcSSMoA"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `100%` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div> */}
        <Footer />
      </div>
    </>
  );
}

export default ContactUs;
