import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function BasicComponents() {
  return (
    <>
      <div className="section section-basic-components">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="7">
              <h2 className="title">Art Core</h2>
              <h6 className="category">Fractal Art</h6>
              <h5 className="description">
              The Escape to Infinity Collection -  Computer Generated Quasi Self-Similar Fractals
              </h5>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="components-macbook"
                  src={require("assets/img/gen_img12.png")}
                ></img>
                <img
                  alt="..."
                  className="table-img"
                  src={require("assets/img/presentation-page/pres46.png")}
                ></img>
                <img
                  alt="..."
                  className="share-btn-img"
                  src={require("assets/img/presentation-page/pres43.png")}
                ></img>
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  src={require("assets/img/presentation-page/pres12.png")}
                ></img>
                <img
                  alt="..."
                  className="coloured-card-img"
                  src={require("assets/img/presentation-page/pres13.png")}
                ></img>
                <img
                  alt="..."
                  className="social-img"
                  src={require("assets/img/presentation-page/pres44.png")}
                ></img>
                <img
                  alt="..."
                  className="linkedin-btn-img"
                  src={require("assets/img/presentation-page/pres31.png")}
                ></img>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default BasicComponents;
