import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Cards() {
  return (
    <>
      <div className="section section-cards">
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" md="8">
              <div className="section-description">
                <h2 className="title">Legal Engineering</h2>
                <h6 className="category">A Solution to Every Problem via Technology</h6>
                <h5 className="description">
                  Legal engineering is a term of art used to frame tehnology solutions that are focused on making the indusrty of law more efficient. 
                </h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="images-container">
                <div className="image-container1 mr-1">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pres07.png")}
                  ></img>
                </div>
                <div className="image-container2 mr-1">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pres10.png")}
                  ></img>
                </div>
                <div className="image-container3 mr-1">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pres11.png")}
                  ></img>
                </div>
                <div className="image-container4 mr-1">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pres09.png")}
                  ></img>
                </div>
                <div className="image-container5">
                  <img
                    alt="..."
                    src={require("assets/img/presentation-page/pres08.png")}
                  ></img>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Cards;
