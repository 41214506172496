import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components

const items = [
  {
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img img-raised rounded"
              src="assets/img/mini01.png"
            ></img>
          </a>
        </div>
        <CardBody>
          <h5 className="card-description">
            "Awesome designs and very well organized code structure! The art contains numerous elements which achieves a balance of aesthetics and assurances of authenticity. Accomplishing this without interfering with the enjoyment of the art is a great feature."
          </h5>
          <CardTitle tag="h4">tenfinney</CardTitle>
          <h6 className="category text-muted">Web3 Engineer</h6>
          <CardFooter>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning"></i>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "0",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img img-raised rounded"
              src="https://bafybeigyqhgzdfenpcj3ku5cupfgljhwg2kndlzih2sggdkq4ey3h6jyfm.ipfs.infura-ipfs.io/"
            ></img>
          </a>
        </div>
        <CardBody>
          <h5 className="card-description">
            "It looks great and it feels futuristic. I look forward to experiemnting with more fractal designs and making them available for the public."
          </h5>
          <CardTitle tag="h4">Scott Stevenson (2)</CardTitle>
          <h6 className="category text-muted">Digital Artist</h6>
          <CardFooter>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning"></i>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "1",
    altText: "",
    caption: "",
  },
  {
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img img-raised rounded"
              src="https://bafybeigyqhgzdfenpcj3ku5cupfgljhwg2kndlzih2sggdkq4ey3h6jyfm.ipfs.infura-ipfs.io/"
            ></img>
          </a>
        </div>
        <CardBody>
          <h5 className="card-description">
            "Images of the Mandelbrot set exhibit an elaborate and infinitely complicated boundary that reveals progressively ever-finer recursive detail at increasing magnifications, making the boundary of the Mandelbrot set a fractal curve. The "style" of this repeating detail depends on the region of the set being examined.[2]" <br></br>
            <br></br>
          </h5>
          <CardTitle tag="h4">Scott Stevenson(3)</CardTitle>
          <h6 className="category text-muted">Web3 Developer and Digital Artist</h6>
          <CardFooter>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning mr-1"></i>
            <i className="fa fa-star text-warning"></i>
          </CardFooter>
        </CardBody>
      </Card>
    ),
    src: "2",
    altText: "",
    caption: "",
  },
];

function Testimonials() {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div
        className="section section-testimonials"
        data-background-color="black"
      >
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title">Self-Executing Animated Fractals</h2>
            <h5 className="description">
            Quasi self-similar fractals reveal ever-finer recursive details progressively at increasing magnifications. Naturally occuring self-similar features can be found in leaves, blood vessels, algae, ocean waves, snowflakes, and even Romanesco broccoli.  <b>Escape to Infinity </b> collection includes multiple components to emphasize the power of the non-fungible token (NFT). This includes{" "}
              <b>(1) an animated segment of the fractal in motion and (2) a self-executing file showing the fractal set in motion.</b>. This is what some clients think::
            </h5>
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <div className="testimonials-people">
              <img
                alt="..."
                className="left-first-person img-raised rellax"
                data-rellax-speed="1"
                src="https://ipfs.infura.io/ipfs/QmQAWfZgyuAhEQUDsQujiaw7qAjN591t26UPU9hqsVuNbS"
              ></img>
              <img
                alt="..."
                className="left-second-person img-raised rellax"
                data-rellax-speed="3"
                src="https://ipfs.infura.io/ipfs/QmRoVCyW5RGwLkhzprRFhpdgjpt4LKsfYRvh3BiRVv6A8a"
              ></img>
              <img
                alt="..."
                className="left-third-person img-raised rellax"
                data-rellax-speed="2"
                src="https://ipfs.infura.io/ipfs/QmbsiA9azM7FSt9PdkBqbzQFK9g4Awu12BoHPkx4iyeYMk"
              ></img>
              <img
                alt="..."
                className="left-fourth-person img-raised rellax"
                data-rellax-speed="5"
                src="https://ipfs.infura.io/ipfs/QmZ6o4htnoofKw5ZvJrtwN1mKBUSftTXmWrYGuSf3NNiKr"
              ></img>
              <img
                alt="..."
                className="left-fifth-person img-raised rellax"
                data-rellax-speed="7"
                src="https://ipfs.infura.io/ipfs/QmQwN8jAmAnW2ZNeYwzoWVHoqjdYjhPfZap5sp6r7Stdvd"
              ></img>
            </div>
          </Col>
          <Col md="8">
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
              {items.map((item, key) => {
                return (
                  <CarouselItem
                    onExiting={onExiting}
                    onExited={onExited}
                    key={key}
                  >
                    {item.content}
                  </CarouselItem>
                );
              })}
              <a
                className="left carousel-control carousel-control-prev"
                data-slide="prev"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  previous();
                }}
                role="button"
              >
                <span className="now-ui-icons arrows-1_minimal-left" />
                <span className="sr-only">Previous</span>
              </a>
              <a
                className="right carousel-control carousel-control-next"
                data-slide="next"
                href="#pablo"
                onClick={(e) => {
                  e.preventDefault();
                  next();
                }}
                role="button"
              >
                <span className="now-ui-icons arrows-1_minimal-right" />
                <span className="sr-only">Next</span>
              </a>
            </Carousel>
          </Col>
          <Col md="2">
            <div className="testimonials-people">
              <img
                alt="..."
                className="right-first-person img-raised rellax"
                data-rellax-speed="5"
                src="https://ipfs.infura.io/ipfs/QmcQFLiWqc9TjUCAAoJ6iBM7Y11FCnmwkbBA9Mnp8SFT7k"
              ></img>
              <img
                alt="..."
                className="right-second-person img-raised rellax"
                data-rellax-speed="1"
                src="https://ipfs.infura.io/ipfs/QmSPF9NsQWykzo89t8f7NQ5qUoeCsiH2xcxtetwqM22LeC"
              ></img>
              <img
                alt="..."
                className="right-fourth-person img-raised rellax"
                data-rellax-speed="7"
                src="https://ipfs.infura.io/ipfs/QmXPaLHS5o5PYjdiEbjX8f3VLgX1sZcQZp64NaWv3fcXa8"
              ></img>
              <img
                alt="..."
                className="right-fifth-person img-raised rellax"
                data-rellax-speed="3"
                src="https://ipfs.infura.io/ipfs/QmQxLtiq177PiiW3Mh97cpzCRZhm1KRKW16cqDyBH6ajcK"
              ></img>
              <img
                alt="..."
                className="right-sixth-person img-raised rellax"
                data-rellax-speed="5"
                src="https://ipfs.infura.io/ipfs/Qmd1xCRhsPm7RSgedspeB8S1jM6GUWuA5TU3GMqnLWvT2x"
              ></img>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Testimonials;
