import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Image() {
  return (
    <>
      <div
        className="section features-7 section-image"
        style={{
          backgroundImage: "url(" + require("assets/img/bg51.png") + ")",
        }}
      >
        <Container fluid>
          <Row>
            <Col className="px-0" md="6">
              <Col sm="12">
                <div className="info info-horizontal">
                  <div className="icon">
                  </div>
                  <div className="description">
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon">
                  </div>
                  <div className="description">
                    <p className="description">
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon">
                  </div>
                  <div className="description">
                    <p className="description">
                    </p>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Image;
